<template>
  <div>
    <div v-for="img in images" class="border-bottom p-1">
      <h5>발란코드: {{img.goods_no}}</h5>
      <div v-for="t in thresholds" class="d-inline-block p-1">
        <div>{{ '여백제거 ' + label[t]}}</div>
        <a :href="`/img/imageTrimSample/${img.goods_no}_0_${t}.jpg`" target="_blank"><img :src="`/img/imageTrimSample/${img.goods_no}_0_${t}.jpg`" style="max-width:250px" /></a>
      </div>
    </div>

    <template v-if="$R('DEV')">
      <hr/>

      <b-button @click="getSamples">샵별 샘플이미지 가져오기</b-button>
      <b-spinner v-if="busy.sample"></b-spinner>

      <div v-for="s in shopSamples">
        <div>{{s.shop_id}}</div>
        <div v-for="i in s.imgs" class="d-inline-block p-1">
          <a :href="i" target="_blank"><img :src="i.replace('.jpg', '_200.webp')" style="max-width:150px" /></a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ImageTrimSample',
  data() {
    return {
      warn: '',
      images: [
        {title: '', goods_no: 5712393},
        {title: '', goods_no: 5918971},
        {title: '', goods_no: 5175656},
      ],
      thresholds: [0, 0.1, 10],
      label: {
        '0': '없음',
        '0.1': '약하게',
        '10': '강하게',
      },
      shopSamples: [],
      busy: {sample: false},
    }
  },
  async created() {
  },
  methods: {
    async getSamples() {
      this.busy.sample = true;
      let j = await this.$api.getJson('/work/shopImageSample');
      this.busy.sample = false;
      this.shopSamples = j.list;
    }
  }
}
</script>
